import React from 'react';
import styles from "./stories-small.module.css";
import asCustomerImg from "./asCustomerImg.svg";
import asDeveloperImg from "./asDeveloperImg.svg";
import revenueImg from "./revenueImg.svg";


function IdeaBoosterStoriesSmall() {
    return (
        <div>
            <div className={`${styles.sMainDiv}`}>
                <div className={`${styles.sMainTitle}`}>
                    Whether you have a production-ready code to sell or want to purchase a reliable code at a fair price, we can help you.
                </div>
                <div className={`${styles.sImage}`}>
                    <img alt='' className={`${styles.sImage}`} src={asDeveloperImg}/>
                </div>
                <div className={`${styles.sBTitle}`}>
                    As a Developer
                </div>
                <div className={`${styles.sBText}`}>
                    In the TandoMarket, you can sell your production-ready code, and pay back all the project's contributors, backers, and upstream developers automatically. All you need to do is register your project on the Tandochain platform. Everything is managed through smart contracts and micropayments.
                </div>
                <div className={`${styles.sText2}`}>
                    By registering your projects on Tandochain you’ll be financially compensated for all your hard work.
                </div>

                <div className={`${styles.sImage}`}>
                    <img alt='' className={`${styles.sImage}`} src={asCustomerImg}/>
                </div>
                <div className={`${styles.sMakeMonetTitle}`}>
                    As a Commercial User
                </div>
                <div className={`${styles.sMakeMonetText}`}>
                    You can find many products registered at a fair price In the TandoMarket. Our new incentivization model aligns your interests with contributors and backers, which drives the development of reliable, high-quality, secure code in a competitive environment in your favor.
                </div>

                <div className={`${styles.sText2_2}`}>
                    By purchasing a code license in the TandoMarket, customers will get a non-fungible token that is tradable in the TandoResale.
                </div>
            </div>
            <div className={styles.revenueDiv}>

                <div className={styles.revenueTextsDiv}>
                        <div className={styles.revenueFirstTitle}>TandoMarket</div>
                        <div className={styles.revenueSecondTitle}>Revenue Sharing Model</div>
                        <div className={styles.revenueText}>
                            When a registered project is sold in the TandoMarket, smart contracts will automatically share the profit it generates between all participants.
                        </div>
                </div>
                <div className={styles.revenueImg}>
                        <img src={revenueImg} alt={'revenueImage'} />
                </div>
            </div>
        </div>
    );
}

export default IdeaBoosterStoriesSmall;

