import React from 'react';
import styles from './header-menu.module.css';
import ProductMenu from "./product/product-menu";
import AboutMenu from "./about/about-menu";
import ContactMenu from "./contact/contact-menu";
import MobileMenu from "./mobile/mobile-menu";


function HeaderMenu(props: {
    currentMenu: string
}) {

    return (
        <div className={`${styles.mainPanel}`}>
            <div className={`${styles.mainContent}`}>

                {props.currentMenu === 'product' &&
                <div className={`${styles.contentPanel}`}>
                    <ProductMenu/>
                </div>
                }
                {props.currentMenu === 'about' &&
                <div className={`${styles.contentPanel}`}>
                    <AboutMenu/>
                </div>
                }
                {props.currentMenu === 'contact' &&
                <div className={`${styles.contentPanel}`}>
                    <ContactMenu/>
                </div>
                }
                {props.currentMenu === 'mobile' &&
                <div className={styles.contentPanelMobile}>
                    <MobileMenu/>
                </div>
                }

            </div>

        </div>
    );
}

export default HeaderMenu;
