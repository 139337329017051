import React from 'react';
import styles from './trust-tando-chain.module.css'

function TrustTandoChain(props:{theme?:string}) {

    function getBackGroundColor(){
        switch(props.theme){
            case 'PrimaryMarket': return '#FF003D';
            case 'IdeaBooster': return '#57CDD7';
            default: return '#4A763B';
        }
    }
    return (
        <div className={styles.wideDiv} style={{backgroundColor:getBackGroundColor()}}>
            <div className={styles.limitedDiv}>
                <div className={styles.titleContainer}>
                    <span className={styles.title}>Why Trust Tandochain?</span>
                </div>

                <div className={styles.contents}>
                    <div className={`${styles.leftContent} ${styles.content}`}>
                        <span className={styles.trustTitle}>Fairness</span>
                        <span className={styles.trustText}>
                            The open-source world relies on passionate volunteers, often driven by reputation, commitment, or pure love for the craft. Yet, their work forms the backbone of the digital economy. Tandochain is building a more equitable future for open-source by rewarding everyone who contributes.
                        </span>
                    </div>
                    <div className={`${styles.rightContent} ${styles.content}`}>
                        <span className={styles.trustTitle}>Transparency</span>
                        <span className={styles.trustText}>
                            Tandochain prioritizes transparency for everyone.  The blockchain ensures a complete and verifiable record of all activity, including registered products, ownership, purchases, and contributor compensation. This allows participants to easily track events and fosters trust within the ecosystem.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TrustTandoChain;
