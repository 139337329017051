import React, {useEffect} from 'react';
import styles from "./secondary-market.module.css";
import secondaryMarketImg from './secondaryMarketImg.svg';
import secondaryMarketMobileImg from "./secondaryMarketMobileImg.svg";
import subTextImg from './subTextImg.svg';
import TrustTandoChain from "../../trust/trust-tando-chain";
import PrimaryMarketStories from "./stories/stories";


function SecondaryMarket() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div>
            <div className={`${styles.aMainDiv}`}>
                        <div className={`${styles.mainText}`}>
                            <div className={`${styles.aTopText}`}>
                                {/*WITH TANDORESALE*/}
                            </div>
                            <div className={`${styles.title}`}>
                                Reduce Your Licensing Costs
                            </div>
                            <div>
                                <img className={`${styles.aboutImageMobile}`} alt={''} src={secondaryMarketMobileImg}/>
                            </div>
                            <div className={`${styles.text}`}>
                                TandoResale is a marketplace that makes it possible for commercial users to resell purchased software licenses to other customers.
                            </div>
                            <div>
                                <img alt='' src={subTextImg} className={styles.subTextImg}/>
                            </div>
                            <div className={`${styles.subText}`}>
                                We are taking the first steps towards a fairer open-source world by giving everyone a fair deal.
                            </div>
                            <div className={styles.aboutImgDiv}>
                                <img className={`${styles.aboutImage}`} alt={''} src={secondaryMarketImg}/>
                            </div>
                        </div>

                    </div>


            <div>
                <PrimaryMarketStories></PrimaryMarketStories>
            </div>
            <div className={styles.chartTexts}>
                <div className={styles.chartText}>
                    Tandochain enables the next generation of software development through new incentivization streams and a sustainable development chain, governed by the ethos of Web3. It aligns the incentives that further the interests of businesses, open-source contributors, and backers, thus creating a positive feedback loop that drives innovation, creates better experiences, and enables more choices.
                </div>
                <div className={styles.chartSubText}>
                    The TandoResale helps commercial users reduce their software licensing costs by selling and buying old versions of software licenses at a fair price. It also motivates developers to maintain and update their software by fixing bugs and adding new features.
                </div>
            </div>
            <div>
                <TrustTandoChain/>
            </div>
        </div>
    );
}

export default SecondaryMarket;
