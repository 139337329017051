import React, { useEffect } from "react";
import styles from "./contact-us.module.css";
import contactImage from "./contact-us.svg";
import contactImageMobile from "./contact-us-mobile.svg";
import emailImage from "./email.svg";
import telegramImage from "./telegram.svg";
import TrustTandoChain from "../../trust/trust-tando-chain";

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className={`${styles.cMainDiv}`}>
        <div className={`${styles.cMainBody}`}>
          <div>
            <div className={`${styles.mainText}`}>
              <div>
                <img
                  className={`${styles.contactImageMobile}`}
                  alt=""
                  src={contactImageMobile}
                />
              </div>
              {/*<div className={`${styles.cTopText}`}>WE ARE AVAILABLE!</div>*/}
              <div className={`${styles.title}`}>Contact Us</div>
              <div className={`${styles.text}`}>
                We’d love to hear from you! Share your feedback to help us enhance our products and deliver more value to our customers.
              </div>
            </div>
          </div>
          <div>
            <img
              className={`${styles.contactImage}`}
              alt=""
              src={contactImage}
            />
          </div>
        </div>
      </div>
      <div className={`${styles.cMainBody}`}>
        <div className={`${styles.email}`}>
          <div className={`${styles.linkImage}`}>
            <img alt="" src={emailImage} />
          </div>
          <div className={`${styles.linkText}`}>Email</div>
          <div className={`${styles.link}`}>
            <a href="mailto:info@tandochain.com">info@tandochain.com</a>
          </div>
        </div>
        <div className={`${styles.telegram}`}>
          <div className={`${styles.linkImage}`}>
            <img alt="" src={telegramImage} />
          </div>
          <div className={`${styles.linkText}`}>Telegram</div>
          <div className={`${styles.link}`}>
            <a href="https://t.me/Tandochain">@Tandochain</a>
          </div>
        </div>
      </div>
      <TrustTandoChain />
    </div>
  );
}

export default ContactUs;
