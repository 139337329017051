import React, {useEffect} from 'react';
import styles from "./primary-market.module.css";
import primaryMarketMobileImg from './primaryMarketMobileImg.svg';
import primaryMarketImg from './primaryMarketImg.svg';
import subTextImg from './subTextImg.svg';
import TrustTandoChain from "../../trust/trust-tando-chain";
import PrimaryMarketStories from "./stories/stories";

function PrimaryMarket() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className={`${styles.aMainDiv}`}>
            <div className={`${styles.mainText}`}>
                            <div className={`${styles.aTopText}`}>
                            {/*    WITH TANDOMARKET*/}
                            </div>
                            <div className={`${styles.title}`}>
                                Make Money with Your Code
                            </div>
                            <div>
                                <img className={`${styles.aboutImageMobile}`} alt={''} src={primaryMarketMobileImg}/>
                            </div>
                            <div className={`${styles.text}`}>
                                TandoMarket is a marketplace that helps commercial users buy high-quality code and compensates the developers when a purchase is made.
                            </div>
                            <div>
                                <img alt='' src={subTextImg} className={styles.subTextImg}/>
                            </div>
                            <div className={`${styles.subText}`}>
                                We are taking the first steps towards a fairer open-source world by giving everyone a fair deal.
                            </div>
                            <div>
                                <img className={`${styles.aboutImage}`} alt={''} src={primaryMarketImg}/>
                            </div>
            </div>

            <div className={`${styles.storiesDiv}`}>
                <PrimaryMarketStories></PrimaryMarketStories>
            </div>
            <div className={styles.chartTexts}>
                <div className={styles.chartText}>
                    Tandochain enables the next generation of software development through new incentivization streams and a sustainable development chain, governed by the ethos of Web3. It aligns the incentives that further the interests of businesses, open-source contributors, and backers, thus creating a positive feedback loop that drives innovation, creates better experiences, and enables more choices.
                </div>
                <div className={styles.chartSubText}>
                    TandoMarket helps developers sell their production-ready code, and pay back all the project's contributors, backers, and upstream developers automatically, while helps commercial users get reliable and high-quality code at a fair price.
                </div>
            </div>
            <div>
                <TrustTandoChain/>
            </div>
        </div>
    );
}

export default PrimaryMarket;
