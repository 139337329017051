import React, {useRef} from 'react';
import styles from './footer.module.css';
import footerLogo from './footerLogo.svg';
import {Alert, Button, Divider, Link, Slide, Snackbar, TextField} from "@mui/material";
import {styled} from '@mui/material/styles';
import {history} from "../../_helpers/history";
import axios from "axios";

export enum MessageSeverity {
    success = 'success',
    info = 'info',
    warning = 'warning',
    error = 'error',
}

export interface Message {
    text: string;
    severity: MessageSeverity;
}

const FooterTextField = styled(TextField)(() => ({
    '& fieldset': {
        borderRadius: '25px',
        height: '40px',
        marginTop: '8px',
    },

    border: '2px  #EBEAED;',
}));

function Footer() {
    // const {executeRecaptcha} = useGoogleReCaptcha();

    const RoundedFooterButton = styled(Button)({
        fontStyle: 'normal',
        fontWeight: 400,
        textAlign: "left",
        borderRadius: '25px',
        textTransform: 'unset',
        fontSize: '16px',
        backgroundColor: '#5A58AB;',
        height: '40px',
    });

    const emailRef = useRef(null);

    function getBackendURL() {
        return process.env.REACT_APP_BACKEND_URL ?? "http://localhost:8040"
    }

    async function saveEmailHandler() {
        await saveEmail().catch((reason) => {
            console.log(JSON.stringify(reason.response));
            showMessage(reason?.response?.data?.response?.message || reason.message,
                MessageSeverity.error)
        });
    }

    async function saveEmail() {
        await axios.post(
            saveEmailUrl +
            // @ts-ignore
            '?email=' + emailRef.current.value
        )
        showMessage(
            'Email address is saved, please go to your mail box and verify your Email address',
            MessageSeverity.success,
        );
    }


    const saveEmailUrl = getBackendURL() + '/api/v1/subscribe';
    // const saveEmail = async () => {
    //     if (!executeRecaptcha) {
    //         return;
    //     }
    //     const token = await executeRecaptcha('subscribe');
    //     console.log('captcha token');
    //     console.log(token);
    //
    //     const requestOptions = {
    //         method: 'POST',
    //         headers: {'Content-Type': 'application/json', 'captcha': token},
    //         // @ts-ignore
    //         body: JSON.stringify({email: emailRef.current.value})
    //     };
    //     fetch(process.env.REACT_APP_BACKEND_URL + '/api/v1/user/subscribe', requestOptions)
    //         .then((resp) => {
    //                 if (resp.status === 204) {
    //                     return;
    //                 }
    //                 if (resp.status !== 201) {
    //                     showMessage("save error", "error")
    //                 } else {
    //                     showMessage("Thanks. The email is saved.", "success")
    //                 }
    //             }
    //             , error => {
    //                 showMessage("fetch error", "error")
    //                 console.error(error);
    //             }
    //         );
    // };

    const [message, setMessage] = React.useState<Message | null>();

    const showMessage = (text: string, severity: MessageSeverity) => {
        setMessage({text: text, severity: severity});
    };

    const handleClose = () => {
        setMessage(null);
    };

    function TransitionRight(props: JSX.IntrinsicAttributes) {
        return <Slide {...props} direction="left"/>;
    }

    return (
        <div className={`${styles.footerMain}`}>
            <Snackbar open={message !== null && message !== undefined}
                // anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                // @ts-ignore
                //       TransitionComponent={TransitionRight}
                      autoHideDuration={2000}
                      onClose={handleClose}
            >
                <Alert
                    severity={message?.severity === "success" ? "success" : "error"}>
                    {(message === null || message === undefined) ? '' : message.text}
                </Alert>
            </Snackbar>
            <div className={`${styles.footerContent}`}>
                <div className={styles.block}>
                    <div className={`${styles.titleMain}`}>
                        <img alt='' src={footerLogo} className={styles.footerLogo}/>
                        <div className={`${styles.title}`}>
                            Tandochain
                        </div>
                    </div>
                    <div className={`${styles.titleText}`}>
                        Read our <a href={'/terms-of-use'}>Terms of Use</a> and <a href={'/privacy-policy'}>Privacy Policy</a>
                    </div>

                    <div className={styles.footerContactInformation}>
                        <a rel="noreferrer" href="https://www.linkedin.com/company/tandochain/" target="_blank">
                            <div className={`${styles.linkedIn} ${styles.contactLogo}`}>

                            </div>

                        </a>
                        <a rel="noreferrer" href="https://twitter.com/tandochain" target="_blank">
                            <div className={`${styles.twitter}  ${styles.contactLogo}`}>

                            </div>

                        </a>
                        <a rel="noreferrer" href="https://t.me/Tandochain" target="_blank">
                            <div className={`${styles.telegram}  ${styles.contactLogo}`}>

                            </div>
                        </a>
                        <a rel="noreferrer" href="https://www.facebook.com/Tandochain.io" target="_blank">
                            <div className={`${styles.facebook}  ${styles.contactLogo}`}>

                            </div>
                        </a>
                        <a rel="noreferrer" href="https://tandochain.medium.com/" target="_blank">
                            <div className={`${styles.medium}  ${styles.contactLogo}`}>

                            </div>
                        </a>
                    </div>
                </div>
                <div className={styles.flexStyle}>
                    <div className={`${styles.firstMenu}`}>
                        <div className={`${styles.footerButton} ${styles.firstFooterMenuHeader}`}>
                            About
                        </div>
                        <div className={`${styles.footerButton}`}>
                            <a href={'/about-us'}>
                                About Us
                            </a>

                        </div>
                        <div className={`${styles.footerButton}`}>
                            <a href={'/contact-us'}>
                                Contact
                            </a>

                        </div>
                        {/* <div className={`${styles.footerButton}`}>
                            <Link className={styles.button} component="button" variant="body2">
                                Team
                            </Link>
                        </div> */}
                    </div>
                    <div className={`${styles.secondMenu}`}>
                        <div className={`${styles.footerButton} ${styles.secondFooterMenuHeader}`}>
                            Company
                        </div>
                        <div className={`${styles.footerButton}`}>

                            <a href={'/privacy-policy'}>
                                Privacy policy
                            </a>
                        </div>
                        <div className={`${styles.footerButton}`}>
                            <a href={'/terms-of-use'}>
                                Terms of use
                            </a>
                        </div>
                        {/*<div className={`${styles.footerButton}`}>*/}
                        {/*    <FooterButton disabled={true} variant="text">FAQ</FooterButton>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className={styles.footerContactInformationMobile}>
                    <a rel="noreferrer" href="https://www.linkedin.com/company/tandochain/" target="_blank">
                        <div className={`${styles.linkedIn} ${styles.contactLogo}`}>

                        </div>

                    </a>
                    <a rel="noreferrer" href="https://twitter.com/tandochain" target="_blank">
                        <div className={`${styles.twitter}  ${styles.contactLogo}`}>

                        </div>

                    </a>
                    <a rel="noreferrer" href="https://t.me/Tandochain" target="_blank">
                        <div className={`${styles.telegram}  ${styles.contactLogo}`}>

                        </div>
                    </a>
                    <a rel="noreferrer" href="https://www.facebook.com/Tandochain.io" target="_blank">
                        <div className={`${styles.facebook}  ${styles.contactLogo}`}>

                        </div>
                    </a>
                    <a rel="noreferrer" href="https://tandochain.medium.com/" target="_blank">
                        <div className={`${styles.medium}  ${styles.contactLogo}`}>

                        </div>
                    </a>
                </div>

                <div className={`${styles.subscribeMain}`}>
                    <div className={`${styles.subscribeTitle}`}>
                        Subscribe to our newsletter
                    </div>
                    <div className={`${styles.subscribeInputs}`}>

                        <div className={styles.textBox}>
                            <FooterTextField inputRef={emailRef}
                                             placeholder="Your email"/>
                        </div>
                        <div className={`${styles.subscribeButton}`}>

                            <RoundedFooterButton onClick={saveEmailHandler} className={styles.roundedFooterButton}
                                                 variant="contained"
                                                 placeholder="">Subscribe</RoundedFooterButton>
                        </div>
                    </div>
                </div>

            </div>

            <div>
                <div className={`${styles.divider}`}>
                    <Divider/>
                </div>
                <div className={`${styles.footerText}`}>
                    All rights reserved by Tandochain. 2022
                </div>
            </div>
        </div>
    );
}

export default Footer;
