import React from 'react';
import styles from './privacy-policy.module.css';
import {useLocation} from "react-router-dom";
import heroSvg from "./hero.svg";
import TrustTandoChain from "../../trust/trust-tando-chain";

function PrivacyPolicy() {

  const location = useLocation();
  let pathname = location.pathname;
  console.log(`pathname: ${pathname}`)

  return (
    <>

      <div className={styles.hero}>
        <div className={styles.heroText}>
          Tandochain Privacy Policy
        </div>
        <img src={heroSvg} className={styles.heroSvg}/>
      </div>
      <div className={styles.mainDiv}>


        <div className={styles.menuDiv}>
          <div className={styles.menuTextDiv}>
            <ul>
              <li><a
                href={`${pathname}#Introduction`}>Introduction</a>
              </li>

              <li><a href={`${pathname}#InformationWeCollect`}>Information We Collect</a></li>

              <li><a href={`${pathname}#HowWeUseYourInformation`}>How We Use Your Information</a></li>

              <li><a href={`${pathname}#DisclosureofYourInformation`}>Disclosure of Your Information</a></li>
              <li><a href={`${pathname}#TandochainandBlockchain`}>Tandochain and Blockchain</a></li>
              <li><a href={`${pathname}#YourChoices`}>Your Choices</a></li>
              <li><a href={`${pathname}#Security`}>Security</a></li>
              <li><a href={`${pathname}#ChildrensPrivacy`}>Children's Privacy</a></li>
              <li><a href={`${pathname}#ChangestothisPrivacyPolicy`}>Changes to this Privacy Policy</a>
              </li>
              <li><a href={`${pathname}#ContactUs`}>Contact Us</a></li>

            </ul>
          </div>
        </div>
        <div className={styles.contentDiv}>
          <div className={styles.update}>Last Updated 03.08.2024</div>
          <div id={'Introduction'} className={styles.title}>Introduction</div>
          <div className={styles.text}>
            Tandochain ("we," "our," "us") is committed to protecting your privacy. This Privacy Policy
            explains
            how we collect, use, and disclose information about you when you use our platform and services.
          </div>

          <div id={'InformationWeCollect'} className={styles.title}>Information We Collect</div>
          <div className={styles.text}>
            We collect the following information about you:
            <ul>
              <li><b>Information you provide to us:</b> This includes your name, email address, and any other
                information
                you choose to provide when creating an account, using the Platform's features, or contacting us
                for support.
              </li>
              <li><b>Information about your use of the Platform:</b> We collect data about how you interact with the
                Platform, such as the pages you visit, the features you use, the searches you perform, and the
                content you access. We may also collect information about your device, such as your IP address,
                browser type, and operating system. We use cookies and similar technologies to enhance your
                experience and analyze usage.
              </li>
            </ul>
          </div>

          <div id={'HowWeUseYourInformation'} className={styles.title}>How We Use Your Information</div>
          <div className={styles.text}>
            We use your information to:

            <ul>
              <li><b>Provide and improve the Platform:</b> We use your information to operate and maintain the Platform,
                personalize your experience, and develop new features and functionalities.
              </li>

              <li><b>Send you information about the Platform:</b> We may use your email address to send you information
                about the Platform, including updates, announcements, and promotional offers. You can opt out of
                receiving marketing emails from us at any time.
              </li>

              <li><b>Respond to your inquiries and requests:</b> We use your information to answer your questions and
                requests for support.
              </li>

              <li><b>Analyze trends and usage patterns:</b> We may use anonymized data to analyze trends and usage
                patterns on the Platform. This information helps us improve the Platform and better understand the
                needs of our users.
              </li>

              <li><b>Comply with legal requirements:</b> We may use your information to comply with applicable laws
                and regulations.
              </li>
            </ul>


          </div>

          <div id={'DisclosureofYourInformation'} className={styles.title}>Disclosure of Your Information</div>
          <div className={styles.text}>
            We may disclose your information to third parties in the following circumstances:

            <ul>
              <li><b>To service providers:</b> We may share your information with third-party service providers who help
                us
                operate the Platform, such as data storage providers, analytics providers, and customer support
                providers. These service providers are obligated to protect your information and use it only for our
                specified purposes.
              </li>

              <li><b>To comply with legal requirements:</b> We may disclose your information to comply with a court
                order,
                subpoena, or other legal process. We may also disclose your information if we believe it is necessary to
                investigate, prevent, or take action against illegal activities or violations of our Terms of Use.
              </li>

              <li><b>In the event of a business transaction:</b> If Tandochain is involved in a merger, acquisition, or
                other
                business transaction, your information may be transferred to the new business owners.
              </li>
            </ul>
          </div>

          <div id={'TandochainandBlockchain'} className={styles.title}>Tandochain and Blockchain</div>
          <div className={styles.text}>
            We utilize blockchain technology to enhance the transparency and integrity of certain platform activities.
            This technology provides a verifiable and immutable record of specific actions on our platform. The types of
            activities recorded on the blockchain include:

            <ul>
              <li><b>Registered Open-Source Projects:</b> Details about projects registered on the platform.</li>

              <li><b>Ownership of Projects:</b> Information on the ownership and management of these projects.</li>

              <li><b>Purchase History:</b> Records of premium services purchased by Commercial Users.</li>

              <li><b>Compensation Distribution:</b> Data regarding the distribution of compensation to Contributors.
              </li>
            </ul>

            While blockchain technology enables transparency, it's important to note that:
            <ul>
              <li><b>Non-Disclosure of Sensitive Information:</b> Tandochain does not store sensitive personal
                information, such as user identities, and email addresses, directly on the blockchain.
              </li>

              <li><b>Limited Disclosure:</b> We may not disclose blockchain-recorded information directly to users, but
                the data can be verified through the blockchain for accuracy and integrity.
              </li>


            </ul>
            For a more detailed technical explanation of how Tandochain interacts with blockchain technology, please
            refer to our Technical Documentation.
          </div>

          <div id={'YourChoices'} className={styles.title}>Your Choices</div>
          <div className={styles.text}>
            <ul>

              <li>You can access and update your account information at any time by logging into your account and
                visiting the settings page.
              </li>
              <li>You can opt out of receiving marketing emails from us by following the unsubscribe instructions in the
                emails we send you.
              </li>
              <li>You can control how cookies are used by your web browser. By using the Platform, you consent to the
                use of cookies following this Privacy Policy.
              </li>
            </ul>
          </div>

          <div id={'Security'} className={styles.title}>Security</div>
          <div className={styles.text}>
            We take reasonable steps to protect your information from unauthorized access, disclosure,
            alteration, or destruction. However, no Internet transmission or electronic storage system is
            ever completely secure.
          </div>

          <div id={'ChildrensPrivacy'} className={styles.title}>Children's Privacy</div>
          <div className={styles.text}>
            The Platform is not intended for children under the age of 18. We do not knowingly collect
            personal information from children under 18. If you are a parent or guardian and you believe
            that your child has provided us with personal information, please contact us. We will take steps
            to delete the information from our servers.
          </div>

          <div id={'ChangestothisPrivacyPolicy'} className={styles.title}>Changes to this Privacy Policy</div>
          <div className={styles.text}>
            We may update this Privacy Policy from time to time. We will post any changes on the Platform.
            We encourage you to review this Privacy Policy periodically to stay informed about how we are
            using your information.
          </div>

          <div id={'ContactUs'} className={styles.title}>Contact Us</div>
          <div className={styles.text}>
            If you have any questions about this Privacy Policy, please contact us at <a
            href={'mailto:info@tandochain.com'}>info@tandochain.com</a>
          </div>

        </div>
      </div>

      <div>
        <TrustTandoChain/>
      </div>

    </>
  );
}

export default PrivacyPolicy;

