import React from 'react';
import styles from './problems.module.css'
import costsImg from './costs.svg'
import passionsImg from './passions.svg'
import risksImg from './risks.svg'

function Problems() {
    // @ts-ignore
    return (
        <div className={styles.firstStepDiv}>
            <div className={styles.title}>
                From Passion Projects to Business Risks: The Open Source Challenge
            </div>
            <div className={styles.problems}>
                <div className={styles.problem}>
                    <div className={styles.icon}>
                        <img className={styles.image} src={costsImg} alt="Costs"/>
                    </div>
                    <div className={styles.content}>
                        <div className={`${styles.problemsTitle} ${styles.problemsTitle1}`}>
                        <span >Free for Business, Costly for Creators</span>
                        </div>
                        <span className={styles.problemsText}>
                            Open-source development is the backbone of the digital world, yet its sustainability is at risk. Unpaid volunteers power this ecosystem, while businesses reap billions in revenue from freely available code. This economic disparity creates an unsustainable model that undermines the foundation of open source.
                        </span>
                    </div>
                </div>
                <div className={styles.problem}>
                    <div className={styles.icon}>
                        <img className={styles.image} src={passionsImg} alt="Passions"/>
                    </div>
                    <div className={styles.content}>
                        <div className={`${styles.problemsTitle} ${styles.problemsTitle2}`}>
                        <span >Passion vs. Paycheck</span>
                        </div>
                        <span className={styles.problemsText}>
                            Many dedicated developers contribute countless hours to open-source projects alongside demanding full-time jobs. Without adequate compensation, maintaining and improving these vital software components becomes increasingly challenging.
                        </span>
                    </div>
                </div>
                <div className={styles.problem}>
                    <div className={styles.icon}>
                        <img className={styles.image} src={risksImg} alt="Risks"/>
                    </div>
                    <div className={styles.content}>
                        <div className={`${styles.problemsTitle} ${styles.problemsTitle3}`}>
                        <span >A Ticking Time Bomb
                        </span>
                        </div>
                        <span className={styles.problemsText}>
                            This unsustainable model is a ticking time bomb. As projects stagnate or are abandoned, the entire software ecosystem faces increased vulnerabilities and costs. The consequences could be catastrophic.
                        </span>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Problems;
