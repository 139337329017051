import React from 'react';
import styles from './home.module.css';

import Hero from "../hero/hero";
import Problems from "../problems/problems";
import TrustTandoChain from "../trust/trust-tando-chain";
import Stories from "../stories/stories";


function Home() {
    return (
        <div>
            <div className={styles.firstStep}>
                <Hero/>
            </div>
            <div className={styles.body}>

                <div className={styles.problems}>
                    <Problems/>
                </div>

                <div className={styles.stories}>
                    <Stories/>
                </div>

            </div>
            <div>
                <TrustTandoChain/>
            </div>

        </div>
    );
}

export default Home;
