import React, {useEffect, useRef, useState} from 'react';

import headerLogoWithText from './headerLogoWithText.svg';
import HeaderMenu from "./menu/header-menu";
import {Link} from "@mui/material";
import {styled} from "@mui/material/styles";
import styles from "./TandoHeader.module.css";
import {history} from "../../_helpers/history";
import openMenuIcon from "./openMenuIcon.svg";

function TandoHeader() {

    const HeaderLink = styled(Link)({
        textDirection: 'none',
        fontSize: '16px !important',
        color: '#1E0E62 !important',
    });
    const [menuName, setMenuName] = useState('');

    function handleMenuActive(newMenuName: string, isHover?:boolean) {
        if (!isHover && isOpen && newMenuName === menuName) {
            setIsOpen(false);
            return
        }
        setMenuName(newMenuName)
        if (newMenuName !== '') {
            setIsOpen(true);
        }
    }

    const menuRef = useRef(null);
    const aboutRef = useRef(null);
    const contactRef = useRef(null);
    const productRef = useRef(null);
    const mobileRef = useRef(null);

    const [listening, setListening] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (listening) return;
        if (menuRef == null || menuRef.current == null || !menuRef.current) return;
        setListening(true);
        [`click`, `touchstart`].forEach((type) => {
            document.addEventListener(`click`, (evt) => {
                const cur = menuRef.current;
                const about = aboutRef.current;
                const contact = contactRef.current;
                const product = productRef.current;
                const mobile = mobileRef.current;


                const node = evt.target;
                if (cur == null) {
                    return;
                }
                // @ts-ignore
                if (node.id === 'product' || node.id === 'about' || node.id === 'contact') {
                    return
                }

                // @ts-ignore
                if (node.id === 'mobile-contact' || node.id === 'mobile-about' || node.id === 'ideaBooster' || node.id === 'primaryMarket' || node.id === 'secondaryMarket' || node.id === 'serviceChannel' || node.id === 'aboutTandochain' || node.id === 'ourTeam' || node.id === 'contact-us') {
                    setIsOpen(false);
                    return;
                }
                // @ts-ignore
                if (cur.contains(node) || about.contains(node) || contact.contains(node) || product.contains(node) || mobile.contains(node)) {
                    return;
                }
                setIsOpen(false);
            });
        });
    });

    return (
        <div className={styles.header}>
            {(menuName !== '' && isOpen) &&
            <div ref={menuRef}>
                <HeaderMenu currentMenu={menuName}/>
            </div>
            }
            <div className={styles.flexDiv}>
                <div className={styles.logoDiv} onClick={() => {
                    window.scrollTo({top: 0, behavior: 'smooth'});
                    history.push('/');
                }}>
                    <img className={styles.logo} src={headerLogoWithText} alt="header"/>
                </div>
                <div className={styles.linksDiv}>
                    <HeaderLink ref={productRef} id='product'
                                className={styles.button}
                        //@ts-ignore
                                component="button"
                                variant="body2"
                                onClick={() => handleMenuActive('product')}
                                onMouseOver={() => handleMenuActive('product', true)}
                    >
                        Solutions
                    </HeaderLink>
                    <HeaderLink ref={aboutRef} id='about'
                                className={styles.button}
                        //@ts-ignore
                                component="button"
                                variant="body2"
                                onClick={() => handleMenuActive('about')}
                                onMouseOver={() => handleMenuActive('about', true)}
                    >
                        About
                    </HeaderLink>
                    <HeaderLink ref={contactRef} id='contact'
                                className={styles.button}
                        //@ts-ignore
                                component="button"
                                variant="body2"
                                onClick={() => handleMenuActive('contact')}
                                onMouseOver={() => handleMenuActive('contact', true)}
                    >
                        Contact
                    </HeaderLink>
                </div>
                <img alt='' ref={mobileRef} className={styles.hButton} src={openMenuIcon}
                     onClick={() => handleMenuActive('mobile')}/>
            </div>

        </div>
    );
}

export default TandoHeader;
