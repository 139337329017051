import React from 'react';
import styles from "../small/stories-small.module.css";
// import bringIdeaImage from "../small/bringIdeaToLifeSmallImg.svg";
import makeMoneyImage from "../small/makeMoneyWithYourCodeSmallImg.svg";
import contactToOriginalImage from '../small/contactToOriginalAuthorsSmallImg.svg';
import reduceCostImage from '../small/reduceYourLicensingCostsSmallImg.svg';


function StoriesSmall() {
    return (
        <div className={`${styles.sMainDiv}`}>
            <div className={`${styles.sMainTitle}`}>
                Empowering All Stakeholders: Fair Incentives for Everyone
            </div>
            <div className={`${styles.sImage}`}>
                <img className={`${styles.sImage}`} src={reduceCostImage}/>
            </div>
            <div className={`${styles.sBTitle}`}>
                Focus on Development, Reap the Rewards
            </div>
            <div className={`${styles.sBText}`}>
                Tandochain empowers maintainers to excel at what they do best: developing and maintaining open-source projects. By leveraging Tandochain's Solutions and offering premium services to commercial users, maintainers can build sustainable businesses while continuing to contribute to the open-source community.
            </div>
            <div className={`${styles.sImage}`}>
                <img className={`${styles.sImage}`} src={makeMoneyImage}/>
            </div>
            <div className={`${styles.sMakeMonetTitle}`}>
                Contribute to Open-Source, Earn Money
            </div>
            <div className={`${styles.sMakeMonetText}`}>
                Open-source projects thrive on the dedication of countless contributors. At Tandochain, we believe these heroes deserve recognition and rewards. Our platform ensures that those who build the open-source world are recognized and rewarded for their valuable work.
            </div>
            <div className={`${styles.sImage}`}>
                <img className={`${styles.sImage}`} src={contactToOriginalImage}/>
            </div>
            <div className={`${styles.sContactToOriginalTitle}`}>
                Access dependable open-source solutions
            </div>
            <div className={`${styles.sContactToOriginalText}`}>
                Tandochain offers a revolutionary platform that connects businesses with a network of talented maintainers providing premium open-source services. Our solutions enable the entire process to be trustless, secure, transparent, and automatic.
            </div>
            {/*<div className={`${styles.sImage}`}>*/}
            {/*    <img className={`${styles.sImage}`} src={reduceCostImage}/>*/}
            {/*</div>*/}
            {/*<div className={`${styles.sReduceCostTitle}`}>*/}
            {/*    Reduce Your*/}
            {/*    Licensing Costs*/}
            {/*</div>*/}
            {/*<div className={`${styles.sReduceCostText}`}>*/}
            {/*    Do you expect to update the code you purchased on the TandoMarket soon? Does that mean buying a new license?  Are you worried about additional costs? It is easy to sell the old valid license in TandoResale. You may even find a new license for a lower price there.*/}
            {/*</div>*/}
        </div>
    );
}

export default StoriesSmall;

