import React from 'react';
import styles from './hero.module.css'
import line from "./line.svg"
import contributor from "./contributor.svg"
import maintainer from "./maintainer.svg"
import commercialUser from "./commercial-user.svg"

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// @ts-ignore
const CustomDot = ({onClick, ...rest}) => {
    const {
        active,
    } = rest;
    // const carouselItems = [CarouselItem1, CaourselItem2, CarouselItem3];
    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.
    return (
        <button
            disabled={active}
            className={active ? styles.active : styles.inactive}
            onClick={() => onClick()}
        >
            {/*{React.Children.toArray(carouselItems)[index]}*/}
        </button>
    );
};
const responsive = {
    all: {
        breakpoint: {max: 3000, min: 0},
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
};

function Hero() {

    const slides = [
        {
            text: 'Open-source maintainers can showcase and monetize premium services securely using Tandochain solutions.',
            image: maintainer
        },
        {
            text: 'Open-source contributors are guaranteed compensation for their efforts on projects they have worked on.',
            image: contributor
        },
        {
            text: 'Commercial users can use Tandochain to connect with maintainers who offer premium open-source services.',
            image: commercialUser
        },
    ];
    // @ts-ignore

    return (
        <div className={styles.firstStepDiv}>
            <div className={styles.titleSubTitleDiv}>
                <div className={styles.limitedDiv}>
                    <div className={styles.titleDiv}>
                        Building a Sustainable Future for Open Source
                    </div>
                    <div className={styles.secondTitleDiv}>
                        Tandochain empowers open-source projects by providing a platform to monetize and support their development, ensuring a sustainable ecosystem for maintainers, contributors, and commercial users.
                    </div>
                </div>
            </div>
            {/*<div className={styles.senderDiv}>*/}
            {/*    <TextField className={styles.textBox}*/}
            {/*        label="Subscribe to our Newsletter"*/}
            {/*    />*/}
            {/*    <Button className={styles.sendButton}>send</Button>*/}
            {/*</div>*/}

            <div className={styles.limitedDiv}>
                <div className={styles.secondDiv}>
                    <div className={styles.contentDiv}>
                        <img alt={''} src={maintainer} className={styles.MaintainerImg}/>
                        <p className={styles.desc3}>Open-source maintainers can showcase and monetize premium services
                            securely using Tandochain solutions.</p>
                    </div>
                    <img alt={''} src={line}/>
                    <div className={styles.contentDiv}>
                        <img alt={''} src={contributor} className={styles.contributorImg}/>
                        <p className={styles.desc1}>Open-source contributors are guaranteed compensation for their efforts on projects they have worked on.</p>
                    </div>
                    <img alt={''} src={line}/>
                    <div className={styles.contentDiv}>
                        <img alt={''} src={commercialUser} className={styles.commercialUserImg}/>
                        <p className={styles.desc2}>Commercial users can use Tandochain to connect with maintainers who
                            offer premium open-source services.</p>
                    </div>
                </div>


                <div className={styles.secondDivMobile}>
                    <Carousel
                        // @ts-ignore
                        customDot={<CustomDot/>}
                        swipeable={true}
                        draggable={true}
                        showDots={true}
                        responsive={responsive}
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={10000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        deviceType={"tablet"}
                        dotListClass={styles.customDotListStyle}
                        itemClass={styles.customItemDotListStyle}
                    >
                        {slides.map((slide, index) => <div className={styles.sliderMain} key={index}>
                            <img alt={''} className={styles.sliderImage} src={slide.image}/>
                            <div className={styles.sliderText}>{slide.text}</div>
                        </div>)}
                    </Carousel>
                </div>
            </div>
        </div>
    );
}

export default Hero;
