import React, {useEffect, useState} from 'react';
import PrimaryMarketStoriesSmall from "./small/stories-small";
import PrimaryMarketStoriesLarge from "./large/stories-large";


function PrimaryMarketStories() {
    function getWindowDimensions() {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div>
            {windowDimensions.width > 1200 &&
            <PrimaryMarketStoriesLarge/>
            }
            {windowDimensions.width <= 1200 &&
            <PrimaryMarketStoriesSmall/>
            }
        </div>
    );
}

export default PrimaryMarketStories;
