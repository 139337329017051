import React from 'react';
import styles from './stories-large.module.css'
import asSellerImg from './asSellerImg.svg'
import asBuyerImg from './asBuyerImg.svg'
import revenueImg from './revenueImg.svg'


function PrimaryMarketStoriesLarge() {
    return (
        <div className={styles.storiesDiv}>
            <div className={styles.title}>
                Whether you want to sell your old valid licenses or buy licenses at a lower price, the TandoResale helps you.
            </div>
            <div className={styles.stories}>
                <div className={styles.story}>
                    <div className={styles.leftContent}>
                        <span className={styles.fistStoriesTitle}>As a Seller</span>
                        <span className={styles.storiesText}>
                            In the TandoResale, you can sell your old valid software license at a fair price. Many projects depend on older versions of libraries, consequently, there are people who are willing to buy the old valid licenses at a lower price.
                        </span>

                        <div className={styles.storiesSubText}>
                            This leads to competition with the TandoMarket, which ultimately reduces its prices.
                        </div>

                    </div>
                    <div>
                        <img src={asSellerImg}  className={styles.icon1} alt="As a Seller"/>
                    </div>
                </div>
                <div className={styles.story}>
                    <div>
                        <img src={asBuyerImg}  className={styles.icon2} alt="As a Buyer"/>
                    </div>
                    <div className={styles.rightContent1}>
                        <span className={styles.storiesTitle}>As a Buyer</span>
                        <span className={styles.storiesText}>
                            In TandoResale, you can buy older versions of software licenses at lower prices. Many projects need to upgrade their libraries to newer versions, so their old libraries' licenses are offered at a fair price in TandoResale. This will create a chance of buying software licenses at lower prices compared to TandoMarket.
                        </span>
                        <div className={styles.storiesSubText}>
                            Lowered prices motivate maintainers to fix bugs and add new features in order to sell newer versions at higher prices.
                        </div>

                    </div>

                </div>
            </div>
             <div className={styles.revenueDiv}>
                <div>
                    <div className={styles.revenueFirstTitle}>TandoResale</div>
                    <div className={styles.revenueSecondTitle}>Revenue Sharing Model</div>
                    <div className={styles.revenueText}>
                        When a license is sold in the TandoResale, smart contracts will automatically transfer the tokens to the seller’s wallet and the license to the buyer’s wallet.
                    </div>
                </div>
                <div className={styles.revenueImg}>
                    <img src={revenueImg} alt={'revenueImage'} />
                </div>
            </div>
        </div>
    );
}

export default PrimaryMarketStoriesLarge;
