import React from 'react';
import styles from './stories-large.module.css'
import makeMoneyWithYourCodeImg from './makeMoneyWithYourCodeImg.svg'
import contactToOriginalAuthorsImg from './contactToOriginalAuthorsImg.svg'
import reduceYourLicensingCostsImg from './reduceYourLicensingCostsImg.svg'
// import learnMoreImg from './learnMoreImg.svg'
// import {history} from "../../../_helpers/history";

function StoriesLarge() {
    return (
        <div className={styles.storiesDiv}>
            <div className={styles.title}>
                Empowering All Stakeholders: Fair Incentives for Everyone
            </div>
            <div className={styles.stories}>
                <div className={styles.story}>
                    <div className={styles.leftContent}>
                        <span className={styles.fistStoriesTitle}>Focus on Development, Reap the Rewards</span>
                        <span className={styles.storiesText}>
                                Tandochain empowers maintainers to excel at what they do best: developing and maintaining open-source projects. By leveraging Tandochain's Solutions and offering premium services to commercial users, maintainers can build sustainable businesses while continuing to contribute to the open-source community.
                        </span>
                        {/*<img src={learnMoreImg} className={styles.learnMoreBtn}/>*/}
                        {/*<button className={styles.learnMoreBtn} onClick={()=>{window.scrollTo({top: 0, behavior: 'smooth'});*/}
                        {/*    history.push('/idea-booster');}}>Learn More</button>*/}
                    </div>
                    <div className={styles.icon1}>
                        <img src={reduceYourLicensingCostsImg}  className={styles.icon1} alt="Bring Idea To Life"/>
                    </div>
                </div>
                <div className={styles.story}>
                    <div className={styles.icon2}>
                        <img src={makeMoneyWithYourCodeImg}  className={styles.icon2} alt="Make Money With Your Code"/>
                    </div>
                    <div className={styles.rightContent1}>
                        <span className={styles.storiesTitle}>Contribute to Open-Source, Earn Money</span>
                        <span className={styles.storiesText}>
                            Open-source projects thrive on the dedication of countless contributors. At Tandochain, we believe these heroes deserve recognition and rewards. Our platform ensures that those who build the open-source world are recognized and rewarded for their valuable work.
                        </span>
                        {/*<button className={styles.learnMoreBtn} onClick={()=>{window.scrollTo({top: 0, behavior: 'smooth'});*/}
                        {/*    history.push('/primary-market');}}>Learn More</button>*/}
                        {/*<img src={learnMoreImg} className={styles.learnMoreBtn}/>*/}
                    </div>

                </div>

                <div className={styles.story}>
                    <div className={styles.leftContent}>
                        <span className={styles.storiesTitle}>Access dependable open-source solutions</span>
                        <span className={styles.storiesText}>
                                Tandochain offers a revolutionary platform that connects businesses with a network of talented maintainers providing premium open-source services. Our solutions enable the entire process to be trustless, secure, transparent, and automatic.
                        </span>
                        {/*<button className={styles.learnMoreBtn} onClick={()=>{window.scrollTo({top: 0, behavior: 'smooth'});*/}
                        {/*    history.push('/service-channel');}}>Learn More</button>*/}
                        {/*<img src={learnMoreImg} className={styles.learnMoreBtn}/>*/}
                    </div>
                    <div className={styles.icon3}>
                        <img src={contactToOriginalAuthorsImg}  className={styles.icon3} alt="Contact Original Authors"/>
                    </div>
                </div>
                {/*<div className={styles.story}>*/}
                {/*    <div className={styles.icon4}>*/}
                {/*        <img src={reduceYourLicensingCostsImg}  className={styles.icon4} alt="Reduce Your Licensing Costs"/>*/}
                {/*    </div>*/}
                {/*    <div className={styles.rightContent2}>*/}
                {/*        <span className={styles.storiesTitle}>Reduce Your Licensing Costs</span>*/}
                {/*        <span className={styles.storiesText}>*/}
                {/*            Do you expect to update the code you purchased on the TandoMarket soon? Does that mean buying a new license?  Are you worried about additional costs? It is easy to sell the old valid license in TandoResale. You may even find a new license for a lower price there.*/}
                {/*        </span>*/}
                {/*        <button className={styles.learnMoreBtn} onClick={()=>{window.scrollTo({top: 0, behavior: 'smooth'});*/}
                {/*            history.push('/secondary-market');}}>Learn More</button>*/}
                {/*        /!*<img src={learnMoreImg} className={styles.learnMoreBtn}/>*!/*/}
                {/*    </div>*/}

                {/*</div>*/}
            </div>

        </div>
    );
}

export default StoriesLarge;
