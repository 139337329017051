import React, {useEffect} from 'react';
import styles from "./about-us.module.css";
import aboutUsImage from './about-us.svg';
import aboutUsImageMobile from './about-us-mobile.svg';
import TrustTandoChain from "../../trust/trust-tando-chain";

function AboutUs() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div>
            <div className={`${styles.aMainDiv}`}>
                <div className={`${styles.aMainBodyTop}`}>
                    <div>
                        <div className={`${styles.mainText}`}>
                            <div>
                                <img className={`${styles.aboutImageMobile}`} alt={''} src={aboutUsImageMobile}/>
                            </div>
                            <div className={`${styles.aTopText}`}>
                                {/*WE ARE ALIVE!*/}
                            </div>
                            <div className={`${styles.title}`}>
                                About Us
                            </div>
                            <div className={`${styles.text}`}>
                                Tandochain's foundation is built on the idea that a healthy open-source world requires recognizing and motivating all participants
                            </div>
                        </div>
                    </div>
                    <div >
                        <img className={`${styles.aboutImage}`} alt={''} src={aboutUsImage}/>
                    </div>
                </div>

            </div>
            <div className={`${styles.aMainBody}`}>
                <div className={`${styles.aFirstColumn}`}>
                    <div className={`${styles.aTitle}`}>
                        About Tandochain
                    </div>
                    <div className={`${styles.aFirstText}`}>
                        Tandochain offers a groundbreaking solution to the open-source funding paradox. By facilitating a direct exchange of value between maintainers and businesses, it ensures the continued innovation and sustainability of the open-source world. This, in turn, empowers businesses and fosters a more vibrant technological landscape for everyone.
                    </div>
                </div>
                <div className={`${styles.aSecondColumn}`}>
                    <div className={`${styles.aTitle}`}>
                        Our Team
                    </div>
                    <div className={`${styles.aSecondText}`}>
                        As a startup, we take pride in our talented and multicultural team. With strong academic backgrounds and diverse industry experience, they bring a wealth of knowledge to Tandochain. More importantly, their high motivation drives them to achieve our missions and goals.
                    </div>
                </div>
            </div>
            <div>
                <TrustTandoChain/>
            </div>
        </div>
    );
}

export default AboutUs;
