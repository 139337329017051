import React from 'react';
import styles from './stories-large.module.css'
// import {ReactComponent as AsDeveloperImg} from './asDeveloperImg.svg'
// import {ReactComponent as AsBackerImg} from './asBackerImg.svg'
// import {ReactComponent as RevenueImg} from './revenueImg.svg'
import asDeveloperImg from './asDeveloperImg.svg'
import asBackerImg from './asBackerImg.svg'
import revenueImg from './revenueImg.svg'


function IdeaBoosterStoriesLarge() {
    return (
        <div className={styles.storiesDiv}>
            <div className={styles.title}>
                Whether you want to develop a new idea or help others make their ideas a reality, you are at the right place.
            </div>
            <div className={styles.stories}>
                <div className={styles.story}>
                    <div className={styles.leftContent}>
                        <span className={styles.fistStoriesTitle}>As a Developer</span>
                        <span className={styles.storiesText}>
                            In TandoBooster, you can share your idea for a new open-source project, whether it is a library or independent software. There are many people who are willing to support you to realize your idea. What you need is to run a fundraising campaign that highlights your deadlines, and funding goals.
                        </span>

                        <div className={styles.storiesSubText}>
                            Even if you don't have any ideas, there may be many great ideas shared by non-developers who need your help to develop them.
                        </div>

                    </div>
                    <div>
                        {/*<AsDeveloperImg className={styles.icon1}/>*/}
                        <img src={asDeveloperImg}  className={styles.icon1} alt="As a Developer"/>
                    </div>
                </div>
                <div className={styles.story}>
                    <div >
                        {/*<AsBackerImg className={styles.icon2}/>*/}
                        <img src={asBackerImg}  className={styles.icon2} alt="As a Backer"/>
                    </div>
                    <div className={styles.rightContent1}>
                        <span className={styles.storiesTitle}>As a Backer</span>
                        <span className={styles.storiesText}>
                            In TandoBooster, you can support great ideas that are worth pursuing. Naturally, developers won’t forget your kindness and will repay you when their products have generated revenue. All these processes will be handled transparently and automatically in the Tandochain platform using smart contracts.
                        </span>
                        <div className={styles.storiesSubText}>
                            Even if you only have the idea for a great project but lack the tech skills, you can share them with those who like to help you develop them.
                        </div>

                    </div>

                </div>
            </div>
            <div className={styles.revenueDiv}>

                <div>
                    <div className={styles.revenueFirstTitle}>TandoBooster</div>
                    <div className={styles.revenueSecondTitle}>Revenue Sharing Model</div>
                    <div className={styles.revenueText}>
                        When the code is production-ready, it will be sold in the TandoMarket, and smart contracts will automatically share the profit it generates between all participants.
                    </div>
                </div>
                <div className={styles.revenueImg}>
                    {/*<RevenueImg/>*/}
                    <img src={revenueImg} alt={'revenueImage'} />
                </div>
            </div>
        </div>
    );
}

export default IdeaBoosterStoriesLarge;
