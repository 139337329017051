import React from 'react';
import styles from "./stories-small.module.css";
import {ReactComponent as AsSellerImg} from "./asSellerImg.svg";
import {ReactComponent as AsBuyerImg} from "./asBuyerImg.svg";
import {ReactComponent as RevenueImg} from "./revenueImg.svg";


function IdeaBoosterStoriesSmall() {
    return (
        <div>
            <div className={`${styles.sMainDiv}`}>
                <div className={`${styles.sMainTitle}`}>
                    Whether you want to sell your old valid licenses or buy licenses at a lower price, the TandoResale helps you.
                </div>
                <div className={`${styles.sImage}`}>
                    <AsSellerImg className={`${styles.sImage}`}/>
                    {/*<img alt='' className={`${styles.sImage}`} src={asDeveloperImg}/>*/}
                </div>
                <div className={`${styles.sBTitle}`}>
                    As a Seller
                </div>
                <div className={`${styles.sBText}`}>
                    In the TandoResale, you can sell your old valid software license at a fair price. Many projects depend on older versions of libraries, consequently, there are people who are willing to buy the old valid licenses at a lower price.
                </div>
                <div className={`${styles.sText2}`}>
                    This leads to competition with the TandoMarket, which ultimately reduces its prices.
                </div>

                <div className={`${styles.sImage}`}>
                    <AsBuyerImg className={`${styles.sImage}`}/>
                    {/*<img alt='' className={`${styles.sImage}`} src={asBackerImg}/>*/}
                </div>
                <div className={`${styles.sMakeMonetTitle}`}>
                    As a Buyer
                </div>
                <div className={`${styles.sMakeMonetText}`}>
                    In TandoResale, you can buy older versions of software licenses at lower prices. Many projects need to upgrade their libraries to newer versions, so their old libraries' licenses are offered at a fair price in TandoResale. This will create a chance of buying software licenses at lower prices compared to TandoMarket.
                </div>

                <div className={`${styles.sText2_2}`}>
                    Lowered prices motivate maintainers to fix bugs and add new features in order to sell newer versions at higher prices.
                </div>
            </div>
            <div className={styles.revenueDiv}>

                <div className={styles.revenueTextsDiv}>
                    <div className={styles.revenueFirstTitle}>TandoResale</div>
                    <div className={styles.revenueSecondTitle}>Revenue Sharing Model</div>
                    <div className={styles.revenueText}>
                        When a license is sold in the TandoResale, smart contracts will automatically transfer the tokens to the seller’s wallet and the license to the buyer’s wallet.
                    </div>
                </div>
                <div className={styles.revenueImg}>
                    <RevenueImg/>
                    {/*<img src={revenueImg} alt={'revenueImage'} />*/}
                </div>
            </div>

        </div>

    );
}

export default IdeaBoosterStoriesSmall;

