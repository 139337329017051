import React from 'react';
import styles from "./stories-small.module.css";
import {ReactComponent as AsCustomerImg} from "./asCustomerImg.svg";
import {ReactComponent as  AsMaintainerImg} from "./asMaintainerImg.svg";
import {ReactComponent as RevenueImg} from "./revenueImg.svg";


function IdeaBoosterStoriesSmall() {
    return (
        <div>
            <div className={`${styles.sMainDiv}`}>
                <div className={`${styles.sMainTitle}`}>
                    Whether you are a maintainer willing to provide service or a commercial user looking for a service contract, you are at the right place.
                </div>
                <div className={`${styles.sImage}`}>
                    <AsCustomerImg className={`${styles.sImage}`}/>
                    {/*<img alt='' className={`${styles.sImage}`} src={asDeveloperImg}/>*/}
                </div>
                <div className={`${styles.sBTitle}`}>
                    As a Commercial User
                </div>
                <div className={`${styles.sBText}`}>
                    In TandoSupport, you can directly contact project maintainers and request additional services. It provides you with a platform to define terms and conditions, enter into a service contract, and establish payment channels. Smart contracts make it possible to handle the whole process transparently and automatically.
                </div>
                <div className={`${styles.sText2}`}>
                    Payment channels help make repeated transfers of tokens between two parties trustless, secure, instantaneous, and without transaction fees.
                </div>

                <div className={`${styles.sImage}`}>
                    <AsMaintainerImg className={`${styles.sImage}`}/>
                    {/*<img alt='' className={`${styles.sImage}`} src={asBackerImg}/>*/}
                </div>
                <div className={`${styles.sMakeMonetTitle}`}>
                    As a Maintainer
                </div>
                <div className={`${styles.sMakeMonetText}`}>
                    In TandoSupport, you can offer your support to commercial users looking for additional services. Smart contracts eliminate third-party involvement and help you make sure you get paid for the provided service. They establish a secure payment channel between you and your customer in a trustless environment.
                </div>

                <div className={`${styles.sText2_2}`}>
                    All payments will be transferred to the maintainer’s wallet based on the terms and conditions of the contract.
                </div>
            </div>
            <div className={styles.revenueDiv}>

                <div className={styles.revenueTextsDiv}>
                        <div className={styles.revenueFirstTitle}>TandoSupport</div>
                        <div className={styles.revenueSecondTitle}>Revenue Sharing Model</div>
                        <div className={styles.revenueText}>
                            When a service contract is established, smart contracts will transfer the tokens to the maintainer’s wallet based on the terms and conditions of the contract.
                        </div>
                </div>
                <div className={styles.revenueImg}>
                    <RevenueImg/>
                        {/*<img src={revenueImg} alt={'revenueImage'} />*/}
                </div>
            </div>

        </div>
    );
}

export default IdeaBoosterStoriesSmall;

