import React from 'react';
import styles from "./stories-small.module.css";

import {ReactComponent as  AsDeveloperImg} from "./asDeveloperImg.svg";

import asBackerImg from "./asBackerImg.svg";
import revenueImg from "./revenueImg.svg";


function IdeaBoosterStoriesSmall() {
    return (
        <div>
            <div className={`${styles.sMainDiv}`}>
                <div className={`${styles.sMainTitle}`}>
                    Whether you want to develop a new idea or help others make their ideas a reality, you are at the right place.
                </div>
                <div className={`${styles.sImage}`}>
                    <AsDeveloperImg className={`${styles.sImage}`}/>
                    {/*<img alt='' className={`${styles.sImage}`} src={asDeveloperImg}/>*/}
                </div>
                <div className={`${styles.sBTitle}`}>
                    As a Developer
                </div>
                <div className={`${styles.sBText}`}>
                    In TandoBooster, you can share your idea for a new open-source project, whether it is a library or independent software. There are many people who are willing to support you to realize your idea. What you need is to run a fundraising campaign that highlights your deadlines, and funding goals.
                </div>
                <div className={`${styles.sText2}`}>
                    Even if you don't have any ideas, there may be many great ideas shared by non-developers who need your help to develop them.
                </div>

                <div className={`${styles.sImage}`}>
                    {/*<AsBackerImg className={`${styles.sImage}`}/>*/}
                    <img alt='' className={`${styles.sImage}`} src={asBackerImg}/>
                </div>
                <div className={`${styles.sMakeMonetTitle}`}>
                    As a Backer
                </div>
                <div className={`${styles.sMakeMonetText}`}>
                    In TandoBooster, you can support great ideas that are worth pursuing. Naturally, developers won’t forget your kindness and will repay you when their products have generated revenue. All these processes will be handled transparently and automatically in the Tandochain platform using smart contracts.
                </div>

                <div className={`${styles.sText2_2}`}>
                    Even if you only have the idea for a great project but lack the tech skills, you can share them with those who like to help you develop them.
                </div>
            </div>
            <div className={styles.revenueDiv}>

                <div className={styles.revenueTextsDiv}>
                        <div className={styles.revenueFirstTitle}>TandoBooster</div>
                        <div className={styles.revenueSecondTitle}>Revenue Sharing Model</div>
                        <div className={styles.revenueText}>
                            When the code is production-ready, it will be sold in the TandoMarket, and smart contracts will automatically share the profit it generates between all participants.
                        </div>
                </div>
                <div className={styles.revenueImg}>
                    {/*<RevenueImg/>*/}
                        <img src={revenueImg} alt={'revenueImage'} />
                </div>
            </div>
        </div>
    );
}

export default IdeaBoosterStoriesSmall;

