import React from 'react';
import styles from './terms-of-use.module.css';
import {useLocation} from "react-router-dom";
import heroSvg from "./hero.svg";
import TrustTandoChain from "../../trust/trust-tando-chain";

function TermsOfUse() {

  const location = useLocation();
  let pathname = location.pathname;
  console.log(`pathname: ${pathname}`)

  return (
    <>

      <div className={styles.hero}>
        <div className={styles.heroText}>
          Tandochain Terms of Use
        </div>
        <img src={heroSvg} className={styles.heroSvg}/>
      </div>
      <div className={styles.mainDiv}>
        <div className={styles.menuDiv}>
          <div className={styles.menuTextDiv}>
            <ul>
              <li><a href={`${pathname}#Introduction`}>Introduction</a></li>
              <li><a href={`${pathname}#Definitions`}>Definitions</a></li>
              <li><a href={`${pathname}#UseofthePlatform`}>Use of the Platform</a></li>
              <li><a href={`${pathname}#Content`}>Content</a></li>
              <li><a href={`${pathname}#Termination`}>Termination</a></li>
              <li><a href={`${pathname}#DisclaimerofWarranties`}>Disclaimer of Warranties</a></li>
              <li><a href={`${pathname}#LimitationofLiability`}>Limitation of Liability</a></li>
              <li><a href={`${pathname}#Indemnification`}>Indemnification</a></li>
              <li><a href={`${pathname}#GoverningLaw`}>Governing Law</a></li>
              <li><a href={`${pathname}#DisputeResolution`}>Dispute Resolution</a></li>
              <li><a href={`${pathname}#EntireAgreement`}>Entire Agreement</a></li>
              <li><a href={`${pathname}#Severability`}>Severability</a></li>
              <li><a href={`${pathname}#Waiver`}>Waiver</a></li>
              <li><a href={`${pathname}#UpdatestotheTermsofUse`}>Updates to the Terms of Use</a></li>

            </ul>
          </div>
        </div>
        <div className={styles.contentDiv}>
          <div className={styles.update}>Last Upadated 01.01.2024</div>
          <div id={'Introduction'} className={styles.title}>Introduction</div>
          <div className={styles.text}>
            These Terms of Use ("Terms") govern your access to and use of the Tandochain platform (the "Platform"). By
            accessing or using the Platform, you agree to be bound by these Terms. If you do not agree to all the Terms,
            you are not authorized to use the Platform.
          </div>
          <div id={'Definitions'} className={styles.title}>Definitions</div>
          <div className={styles.text}>
            <ul>
              <li><b>"Maintainer"</b> means a person or entity that develops and maintains an open-source project.</li>
              <li>"Contributor" means a person or entity that provides code, documentation, or other contributions to an
                open-source project.
              </li>
              <li><b>"Commercial User"</b> means a business or other entity that uses open-source projects for commercial
                purposes.
              </li>
              <li><b>"Solutions"</b> means the features and services offered by the Tandochain platform.</li>
              <li><b>"Content"</b> means all information, data, text, software, code, scripts, graphics, photos, sounds, music,
                videos, and other materials that are displayed on or available through the Platform.
              </li>
              <li><b>"Intellectual Property"</b> means patents, copyrights, trademarks, trade secrets, and other intellectual
                property rights.
              </li>
            </ul>

          </div>
          <div id={'UseofthePlatform'} className={styles.title}>Use of the Platform</div>
          <div className={styles.text}>
            <ul>
              <li>You must be at least 18 years old to use the Platform.</li>
              <li>To access certain features of the Platform, you may be required to create an account.</li>
              <li>You agree to provide accurate, complete, and up-to-date information.</li>
              <li>You are responsible for maintaining the confidentiality of your account information and for all
                activity that occurs under your account.
              </li>
              <li>You agree to use the Platform in a manner that is consistent with all applicable laws and
                regulations.
              </li>
              <li>You agree not to use the Platform for any illegal or unauthorized purpose.</li>
              <li>You agree not to interrupt or attempt to interrupt the operation of the Platform.</li>
              <li>You agree not to infringe on the Intellectual Property of others.</li>
              <li>You agree not to transmit any viruses or other harmful code through the Platform.</li>
              <li>You agree to comply with Tandochain's acceptable use policies, which may be updated from time to time
                and posted on the Platform.
              </li>

            </ul>
          </div>
          <div id={'Content'} className={styles.title}>Content</div>
          <div className={styles.text}>
            <ul>
              <li>The Platform may contain Content that is owned by Tandochain or by third parties.</li>

              <li>You may not access, use, copy, modify, distribute, or otherwise exploit any Content without the
                express
                written permission of Tandochain or the applicable third party.
              </li>
              <li>You are solely responsible for all Content that you upload or post to the Platform. You represent and
                warrant that you have all necessary rights to upload or post such Content to the Platform and that such
                Content does not infringe on the Intellectual Property or other rights of any third party.
              </li>
              <li>By submitting content to the Platform, you grant Tandochain a non-exclusive, royalty-free, perpetual,
                and
                worldwide license to use, display, and distribute such content in connection with the Platform.
              </li>
            </ul>
          </div>
          <div id={'Termination'} className={styles.title}>Termination</div>
          <div className={styles.text}>
            We may suspend or terminate your account or access to the Platform at any time, with or without cause, and
            with or without notice, if we believe you have violated these Terms of Use.
          </div>
          <div id={'DisclaimerofWarranties'} className={styles.title}>Disclaimer of Warranties</div>
          <div className={styles.text}>
            The Platform is provided "as is" and "as available" without any warranties of any kind, either express or
            implied. We do not warrant that the Platform will be uninterrupted, or error-free.
          </div>
          <div id={'LimitationofLiability'} className={styles.title}>Limitation of Liability</div>
          <div className={styles.text}>
            To the fullest extent permitted by law, Tandochain shall not be liable for any indirect, incidental,
            special, or consequential damages arising from your use of the Platform or inability to use the Platform.
          </div>
          <div id={'Indemnification'} className={styles.title}>Indemnification</div>
          <div className={styles.text}>
            You agree to indemnify and hold Tandochain harmless from any claims, losses, damages, or liabilities,
            including attorney's fees, arising out of or related to your use of the Platform, your violation of these
            Terms, or your violation of any rights of a third party.
          </div>
          <div id={'GoverningLaw'} className={styles.title}>Governing Law</div>
          <div className={styles.text}>
            These Terms of Use shall be governed by and construed in accordance with the laws of the Netherlands,
            without regard to its conflict of law principles.
          </div>
          <div id={'DisputeResolution'} className={styles.title}>Dispute Resolution</div>
          <div className={styles.text}>
            Any dispute arising out of or relating to these Terms will be resolved by binding arbitration following the
            rules of a reputable arbitration provider. The arbitration shall be held in Amsterdam, Netherlands.
          </div>
          <div id={'EntireAgreement'} className={styles.title}>Entire Agreement</div>
          <div className={styles.text}>
            These Terms constitute the entire agreement between you and Tandochain concerning your use of the Platform.
          </div>
          <div id={'Severability'} className={styles.title}>Severability</div>
          <div className={styles.text}>
            If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck and
            the remaining provisions shall remain in full force and effect.
          </div>
          <div id={'Waiver'} className={styles.title}>Waiver</div>
          <div className={styles.text}>
            No waiver of any breach of these Terms by Tandochain shall be deemed a waiver of any subsequent breach.
          </div>
          <div id={'UpdatestotheTermsofUse'} className={styles.title}>Updates to the Terms of Use</div>
          <div className={styles.text}>
            Tandochain may update these Terms of Use from time to time. We will post any changes on the Platform and
            notify you by email (if you have provided us with an email address). We encourage you to review these Terms
            of Use periodically to stay informed about any updates.

            Your continued use of the Platform after the posting of any changes to these Terms of Use constitutes your
            acceptance of those changes.
          </div>

        </div>
      </div>

      <div>
        <TrustTandoChain/>
      </div>

    </>
  );
}

export default TermsOfUse;

