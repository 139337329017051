import React from 'react';
import styles from './contact-menu.module.css';
import contactImage from './contact.svg';
import email from "./email.svg";
import bigTelegram from "./telegram2.svg";
import {history} from "../../../../_helpers/history";


function ContactMenu() {

  return (
    <div className={`${styles.mainPanel}`}>
      <div className={`${styles.titlePanel}`}>
        <div className={`${styles.titleFirstRow}`}>
          <div className={`${styles.titleImage}`}>
            <img src={contactImage} alt='Contact'/>
          </div>
          <div className={`${styles.titleTopText}`}>
            Contact
          </div>
        </div>
        <div className={`${styles.titleBellowText}`}>
          We’d love to hear from you! Share your feedback to help us enhance our products and deliver more value to our
          customers.
        </div>
      </div>
      <div className={`${styles.firstColumn}`}>
        <div>
          <div className={`${styles.titleFirstRow}`}>
            <div id='contact-us' className={`${styles.menuItemTopText} ${styles.firstRowTopText}`} onClick={() => {
              window.scrollTo({top: 0, behavior: 'smooth'});
              history.push('/contact-us');
            }}>
              Reach out to us!
            </div>

          </div>
          <div>
            <div className={`${styles.firstRowSubText}`}>
              Let's make something awesome together
            </div>
          </div>
          <div className={`${styles.firstContactsRow}`}>
            <a href="mailto:info@tandochain.com" className={styles.contactsLink}>
              <div className={`${styles.contactEmail}`}>

              </div>
              <div className={`${styles.contactText}`}>
                info@tandochain.com
              </div>
            </a>
          </div>
          <div className={`${styles.contactsRow}`}>

            <a href="https://t.me/Tandochain" className={styles.contactsLink}>
              <div className={`${styles.contactTelegram}`}>

              </div>
              <div className={`${styles.contactText}`}>
                @tandochain
              </div>
            </a>

          </div>
        </div>
      </div>
      <div className={`${styles.secondColumn}`}>
        <div>
          <div className={`${styles.secondColumnFirstRow}`}>

            Follow us on social media:

            <div className={styles.contactInformation}>
              <a href="https://www.linkedin.com/company/tandochain/" target="_blank">
                <div className={`${styles.linkedIn} ${styles.contactLogo}`}>

                </div>

              </a>
              <a href="https://twitter.com/tandochain" target="_blank">
                <div className={`${styles.twitter}  ${styles.contactLogo}`}>

                </div>

              </a>
              <a href="https://t.me/Tandochain" target="_blank">
                <div className={`${styles.telegram}  ${styles.contactLogo}`}>

                </div>
              </a>
              <a href="https://www.facebook.com/Tandochain.io" target="_blank">
                <div className={`${styles.facebook}  ${styles.contactLogo}`}>

                </div>
              </a>
              <a href="https://tandochain.medium.com/" target="_blank">
                <div className={`${styles.medium}  ${styles.contactLogo}`}>

                </div>
              </a>
            </div>
          </div>

        </div>

      </div>

    </div>
  );
}

export default ContactMenu;
