import React from 'react';
import styles from './stories-large.module.css'
import asCustomerImg from './asCustomerImg.svg'
import asMaintainerImg from './asMaintainerImg.svg'
import revenueImg from './revenueImg.svg'


function PrimaryMarketStoriesLarge() {
    return (
        <div className={styles.storiesDiv}>
            <div className={styles.title}>
                Whether you are a maintainer willing to provide service or a commercial user looking for a service contract, you are at the right place.
            </div>
            <div className={styles.stories}>
                <div className={styles.story}>
                    <div className={styles.leftContent}>
                        <span className={styles.fistStoriesTitle}>As a Commercial User</span>
                        <span className={styles.storiesText}>
                            In TandoSupport, you can directly contact project maintainers and request additional services. It provides you with a platform to define terms and conditions, enter into a service contract, and establish payment channels. Smart contracts make it possible to handle the whole process transparently and automatically.
                        </span>

                        <div className={styles.storiesSubText}>
                            Payment channels help make repeated transfers of tokens between two parties trustless, secure, instantaneous, and without transaction fees.
                        </div>

                    </div>
                    <div>
                        <img src={asCustomerImg}  className={styles.icon1} alt="As a Customer"/>
                    </div>
                </div>
                <div className={styles.story}>
                    <div>
                        <img src={asMaintainerImg}  className={styles.icon2} alt="As a Maintainer"/>
                    </div>
                    <div className={styles.rightContent1}>
                        <span className={styles.storiesTitle}>As a Maintainer</span>
                        <span className={styles.storiesText}>
                            In TandoSupport, you can offer your support to commercial users looking for additional services. Smart contracts eliminate third-party involvement and help you make sure you get paid for the provided service. They establish a secure payment channel between you and your customer in a trustless environment.
                        </span>
                        <div className={styles.storiesSubText}>
                            All payments will be transferred to the maintainer’s wallet based on the terms and conditions of the contract.
                        </div>

                    </div>

                </div>
            </div>
             <div className={styles.revenueDiv}>

                <div>
                    <div className={styles.revenueFirstTitle}>TandoSupport</div>
                    <div className={styles.revenueSecondTitle}>Revenue Sharing Model</div>
                    <div className={styles.revenueText}>
                        When a service contract is established, smart contracts will transfer the tokens to the maintainer’s wallet based on the terms and conditions of the contract.
                    </div>
                </div>
                <div className={styles.revenueImg}>
                    <img src={revenueImg} alt={'revenueImage'} />
                </div>
            </div>
        </div>
    );
}

export default PrimaryMarketStoriesLarge;
