import React from 'react';
import styles from './product-menu.module.css';
import productImage from './product.svg';
import ideaBoosterImage from './idea-booster.svg';
import primaryMarketImg from './primary-market.svg';
import secondaryMarketImage from './secondary-market.svg';
import serviceChanelImage from './service-chanel.svg';


function ProductMenu() {

    return (
        <div className={`${styles.mainPanel}`}>
            <div className={`${styles.titlePanel}`}>
                <div className={`${styles.titleFirstRow}`}>
                    <div className={`${styles.titleImage}`}>
                        <img alt='' src={productImage}/>
                    </div>
                    <div className={`${styles.titleTopText}`}>
                        TandoMarket
                    </div>
                </div>
                <div className={`${styles.titleBellowText}`}>
                    Tando Market provides a unique marketplace tailored for open-source projects, connecting them with commercial users seeking reliable services. More than just linking you to potential customers, Tando Market empowers you to build a thriving project by offering:
                </div>
            </div>
            <div className={`${styles.firstColumn}`}>
                <div>
                    <div  className={`${styles.titleFirstRow}`}>
                        <div className={`${styles.ideaBoosterImage}`}>
                            <img id='ideaBooster' alt='' src={ideaBoosterImage}/>
                        </div>
                        <div id='ideaBooster' className={`${styles.menuItemTopText} ${styles.firstRowTopText} ${styles.link}`} onClick={()=>{document.location.href = 'https://www.tandochain.com/market/landing-page'}}>
                            Secure Transactions
                        </div>
                    </div>
                    <div className={`${styles.firstColumnBellowText}`}>
                        Leverage blockchain technology for secure and transparent financial transactions, ensuring trust between you and your customers.
                    </div>
                </div>
                <div>
                    <div  className={`${styles.titleFirstRow}`}>
                        <div  className={`${styles.primaryMarketImage}`}>
                            <img id='primaryMarket' alt='' src={primaryMarketImg}/>
                        </div>
                        <div id='primaryMarket' className={`${styles.menuItemTopText} ${styles.secondRowTopText} ${styles.link}`} onClick={()=>{document.location.href = 'https://www.tandochain.com/market/landing-page'}}>
                            Royalty Distribution
                        </div>
                    </div>
                    <div className={`${styles.firstColumnBellowText}`}>
                        Smart contracts ensure fair and timely distribution of project funds among all contributors, fostering a collaborative environment.
                    </div>
                </div>
            </div>
            <div className={`${styles.secondColumn}`}>
                <div>
                    <div className={`${styles.titleFirstRow}`}>
                        <div className={`${styles.ideaBoosterImage}`}>
                            <img id='secondaryMarket' alt='' src={secondaryMarketImage}/>
                        </div>
                        <div id='secondaryMarket' className={`${styles.menuItemTopText} ${styles.firstRowTopText} ${styles.link}`} onClick={()=>{document.location.href = 'https://www.tandochain.com/market/landing-page'}}>
                            Building Reputation
                        </div>
                    </div>
                    <div className={`${styles.firstColumnBellowText}`}>
                        User ratings and reviews on Tando Market help you build credibility and attract new clients who value your expertise.
                    </div>
                </div>
                <div>
                    <div className={`${styles.titleFirstRow}`}>
                        <div className={`${styles.primaryMarketImage}`}>
                            <img id='serviceChannel' alt='' src={serviceChanelImage}/>
                        </div>
                        <div id='serviceChannel' className={`${styles.menuItemTopText} ${styles.secondRowTopText} ${styles.link}`} onClick={()=>{document.location.href = 'https://www.tandochain.com/market/landing-page'}}>
                            Streamlined Operations
                        </div>
                    </div>
                    <div className={`${styles.firstColumnBellowText}`}>
                        Free yourself from administrative burdens. Automate tasks like escrow management, refund processing, and even dispute resolution through secure smart contracts.
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ProductMenu;
