import React from 'react';
import Home from "./components/pages/home";
import {history} from './_helpers/history';
// @ts-ignore
import {Route, Router} from 'react-router-dom';
import styles from "./components/pages/home.module.css";
import TandoHeader from "./components/header/TandoHeader";
import Footer from "./components/footer/footer";
import ContactUs from "./components/pages/contact-us/contact-us";
import AboutUs from "./components/pages/about-us/about-us";
import IdeaBooster from "./components/pages/idea-booster/idea-booster";
import PrimaryMarket from "./components/pages/primary-market/primary-market";
import SecondaryMarket from "./components/pages/secondary-market/secondary-market";
import ServiceChannel from "./components/pages/service-channel/service-channel";
// import './App.css';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import PrivacyPolicy from "./components/pages/privacy-policy/privacy-policy";
import TermsOfUse from "./components/pages/terms-of-use/terms-of-use";

function App() {
    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            scriptProps={{
                async: false, // optional, default to false,
                defer: false, // optional, default to false
                appendTo: 'head', // optional, default to "head", can be "head" or "body",
                nonce: undefined // optional, default undefined
            }}
        >
            <div className={`${styles.mainPanel}`}>
                <div>
                    <TandoHeader/>
                </div>
                <Router history={history}>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/contact-us" component={ContactUs}/>
                    <Route exact path="/about-us" component={AboutUs}/>
                    <Route exact path="/idea-booster" component={IdeaBooster}/>
                    <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
                    <Route exact path="/terms-of-use" component={TermsOfUse}/>
                    <Route exact path="/primary-market" component={PrimaryMarket}/>
                    <Route exact path="/secondary-market" component={SecondaryMarket}/>
                    <Route exact path="/service-channel" component={ServiceChannel}/>
                </Router>
                <div className={styles.body}>
                    <Footer/>
                </div>
            </div>
        </GoogleReCaptchaProvider>
    );
}

export default App;
