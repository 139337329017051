import React from 'react';
import styles from './about-menu.module.css';
import aboutImage from './about.svg';
import teamImage from './team.svg';
import aboutTandoChainImage from './aboutTandoChain.svg';
import {history} from "../../../../_helpers/history";


function AboutMenu() {

    return (
        <div className={`${styles.mainPanel}`}>
            <div className={`${styles.titlePanel}`}>
                <div className={`${styles.titleFirstRow}`}>
                    <div className={`${styles.titleImage}`}>
                        <img src={aboutImage} alt='about'/>
                    </div>
                    <div className={`${styles.titleTopText}`}>
                        About
                    </div>
                </div>
                <div className={`${styles.titleBellowText}`}>
                    Tandochain's foundation is built on the idea that a healthy open-source world requires recognizing and motivating all participants
                </div>
            </div>
            <div className={`${styles.firstColumn}`}>
                <div>
                    <div className={`${styles.titleFirstRow}`}>
                        <div className={`${styles.ideaBoosterImage}`}>
                            <img id='aboutTandochain' src={aboutTandoChainImage} alt='About Tandochain'/>
                        </div>
                        <div id='aboutTandochain' className={`${styles.menuItemTopText} ${styles.firstRowTopText}`} onClick={()=>{window.scrollTo({top: 0, behavior: 'smooth'});
                            history.push('/about-us');}}>
                            About Tandochain
                        </div>
                    </div>
                    <div className={`${styles.firstColumnBellowText}`}>
                        Tandochain offers a groundbreaking solution to the open-source funding paradox. By facilitating a direct exchange of value between maintainers and businesses, it ensures the continued innovation and sustainability of the open-source world. This, in turn, empowers businesses and fosters a more vibrant technological landscape for everyone.
                    </div>
                    {/*<div className={`${styles.decentralizedPanel}`}>*/}
                    {/*    Web3 and decentralized technologies help us reach our goals through smart contracts, micropayments, and trustless governance.*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className={`${styles.secondColumn}`}>
                <div>
                    <div className={`${styles.titleFirstRow}`}>
                        <div className={`${styles.ideaBoosterImage}`}>
                            <img id='ourTeam' src={teamImage} alt='Our Team' />
                        </div>
                        <div id='ourTeam' className={`${styles.menuItemTopText} ${styles.firstRowTopText}`} onClick={()=>{window.scrollTo({top: 0, behavior: 'smooth'});
                            history.push('/about-us');}}>
                            Our Team
                        </div>
                    </div>
                    <div className={`${styles.firstColumnBellowText}`}>
                        As a startup, we take pride in our talented and multicultural team. With strong academic backgrounds and diverse industry experience, they bring a wealth of knowledge to Tandochain. More importantly, their high motivation drives them to achieve our missions and goals.
                    </div>
                </div>

            </div>

        </div>
    );
}

export default AboutMenu;
