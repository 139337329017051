import React, {useEffect} from 'react';
import styles from "./service-channel.module.css";
import serviceChannelImg from './serviceChannelImg.svg';
import serviceChannelMobileImg from './serviceChannelMobileImg.svg';
import subTextImg from './subTextImg.svg';
import TrustTandoChain from "../../trust/trust-tando-chain";
import ServiceChanneltStories from "./stories/stories";

function ServiceChannel() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
            <div className={`${styles.aMainDiv}`}>
                        <div className={`${styles.mainText}`}>
                            <div className={`${styles.aTopText}`}>
                                {/*WITH TANDOSUPPORT*/}
                            </div>
                            <div className={`${styles.title}`}>
                                Contact Original Authors
                            </div>
                            <div>
                                <img className={`${styles.aboutImageMobile}`} alt={''} src={serviceChannelMobileImg}/>
                            </div>
                            <div className={`${styles.text}`}>
                                TandoSupport is a platform where commercial users can communicate with project maintainers, and ask them for additional services.
                            </div>
                            <div>
                                <img alt='' src={subTextImg} className={styles.subTextImg}/>
                            </div>
                            <div className={`${styles.subText}`}>
                                We are taking the first steps towards a fairer open-source world by giving everyone a fair deal.
                            </div>
                            <div>
                                <img className={`${styles.aboutImage}`} alt={''} src={serviceChannelImg}/>
                            </div>


            </div>
            <div>
                <ServiceChanneltStories></ServiceChanneltStories>
            </div>
            <div className={styles.chartTexts}>
                <div className={styles.chartText}>
                    Tandochain enables the next generation of software development through new incentivization streams and a sustainable development chain, governed by the ethos of Web3. It aligns the incentives that further the interests of businesses, open-source contributors, and backers, thus creating a positive feedback loop that drives innovation, creates better experiences, and enables more choices.
                </div>
                <div className={styles.chartSubText}>
                    The TandoSupport helps commercial users ask for additional services from maintainers and establish a secure payment channel between two parties in a trustless environment.
                </div>
            </div>
            <div>
                <TrustTandoChain/>
            </div>
        </div>
    );
}

export default ServiceChannel;
