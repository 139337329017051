import React from 'react';
import styles from './mobile-menu.module.css';

import ideaBoosterImage from './idea-booster.svg';
import primaryMarketImg from './primary-market.svg';
import secondaryMarketImage from './secondary-market.svg';
import serviceChanelImage from './service-chanel.svg';
import {history} from "../../../../_helpers/history";


function MobileMenu() {

    return (
        <div>
            <div className={styles.titlePanel}>
                <div className={styles.titleFirstRow}>
                    <div className={styles.titleTopText}>
                        TandoMarket
                    </div>
                </div>
            </div>

            <div className={styles.otherRows}>
                <div className={styles.ideaBoosterImage}>
                    <img id='ideaBooster' alt='' src={ideaBoosterImage}/>
                </div>
                <div id='ideaBooster' className={`${styles.menuItemTopText}`}  onClick={()=>{document.location.href = 'https://www.tandochain.com/market/landing-page'}}>
                    Secure Transactions
                </div>
            </div>
            <div className={styles.otherRows}>
                <div className={styles.primaryMarketImage}>
                    <img id='primaryMarket' alt='' src={primaryMarketImg}/>
                </div>
                <div id='primaryMarket' className={`${styles.menuItemTopText}`}  onClick={()=>{document.location.href = 'https://www.tandochain.com/market/landing-page'}}>
                    Royalty Distribution
                </div>
            </div>
            <div className={styles.otherRows}>
                <div className={styles.ideaBoosterImage}>
                    <img id='secondaryMarket' alt='' src={secondaryMarketImage}/>
                </div>
                <div id='secondaryMarket' className={`${styles.menuItemTopText}`}  onClick={()=>{document.location.href = 'https://www.tandochain.com/market/landing-page'}}>
                    Building Reputation
                </div>
            </div>
            <div className={styles.otherRows}>
                <div className={styles.primaryMarketImage}>
                    <img id='serviceChannel' alt='' src={serviceChanelImage}/>
                </div>
                <div id='serviceChannel' className={`${styles.menuItemTopText}`}  onClick={()=>{document.location.href = 'https://www.tandochain.com/market/landing-page'}}>
                    Streamlined Operations
                </div>
            </div>

            <div className={styles.footerPanel}>

                <div id='mobile-contact' className={styles.footerText} onClick={(evt) => {
                    window.scrollTo({top: 0, behavior: 'smooth'});
                    history.push('/contact-us');
                }}>
                    Contact Us
                </div>
                &nbsp;.&nbsp;
                <div id='mobile-about' className={styles.footerText} onClick={(evt) => {
                    window.scrollTo({top: 0, behavior: 'smooth'});
                    history.push('/about-us');
                }}>
                    About
                </div>

            </div>

        </div>
    );
}

export default MobileMenu;
