import React, {useEffect} from 'react';
import styles from "./idea-booster.module.css";
import IdeaBoosterMobileImg from './ideaBoosterMobileImg.svg';
import IdeaBoosterImg from './ideaBoosterImg.svg';
import subTextImg from './subTextImg.svg';
import TrustTandoChain from "../../trust/trust-tando-chain";
import IdeaBoosterStories from "./stories/stories";

function IdeaBooster() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
            <div className={`${styles.aMainDiv}`}>
                        <div className={`${styles.mainText}`}>
                            <div className={`${styles.aTopText}`}>
                            {/*    WITH TANDOBOOSTER*/}
                            </div>
                            <div className={`${styles.title}`}>
                                Bring Your Ideas to Life
                            </div>
                            <div>
                                <img src={IdeaBoosterMobileImg} className={`${styles.aboutImageMobile}`}/>
                            </div>
                            <div className={`${styles.text}`}>
                                TandoBooster is a platform that allows developers and backers to realize new ideas and share the profit it generates.
                            </div>
                            <div>
                                <img alt='' src={subTextImg} className={styles.subTextImg}/>
                            </div>
                            <div className={`${styles.subText}`}>
                                We are taking the first steps towards a fairer open-source world by giving everyone a fair deal.
                            </div>
                            <div>
                                {/*<IdeaBoosterImg className={`${styles.aboutImage}`}/>*/}
                                <img src={IdeaBoosterImg} className={`${styles.aboutImage}`}/>
                            </div>
                        </div>


            <div className={`${styles.storiesDiv}`}>
                <IdeaBoosterStories></IdeaBoosterStories>
            </div>
             <div className={styles.chartTexts}>
                <div className={styles.chartText}>
                    Tandochain enables the next generation of software development through new incentivization streams and a sustainable development chain, governed by the ethos of Web3. It aligns the incentives that further the interests of businesses, open-source contributors, and backers, thus creating a positive feedback loop that drives innovation, creates better experiences, and enables more choices.
                </div>
                <div className={styles.chartSubText}>
                    TandoBooster helps developers accelerate innovation and bring their ideas to life without worrying about the costs while helping backers remain sufficiently motivated to participate.
                </div>
            </div>
            <div>
                <TrustTandoChain/>
            </div>
        </div>
    );
}

export default IdeaBooster;
